<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1>{{ $t('message.feedback') }}</h1>
            <p class="lead">
              {{ $t('message.feedback_intro') }}
            </p>
          </div>
          <form @submit.prevent="submit">
        <b-row>
          <!-- nome -->
          <b-col sm="12">
            <validation-field :label="$t('message.first_name')" :v="$v.form.Name" >
              <input class="form-control"
                     maxlength="50"
                     type="text" v-model="form.Name"
                     v-on:blur="$v.form.Name.$touch()">
            </validation-field>
          </b-col>
        </b-row>
        <b-row>
          <!-- email -->
          <b-col sm="12">
            <validation-field :label="$t('message.email_anonymous')" :v="$v.form.Email" >
              <input class="form-control"
                     maxlength="50"
                     type="text" v-model="form.Email"
                     v-on:blur="$v.form.Email.$touch()">
            </validation-field>
          </b-col>
        </b-row>
        <b-row>
          <!-- messaggio -->
          <b-col sm="12">
            <validation-field
              :label="$t('message.message')"
              :v="$v.form.Feedback">
                <textarea class="form-control" id="Feedback"
                          type="textarea"
                          rows="15"
                          v-model="form.Feedback"
                          v-on:blur="$v.form.Feedback.$touch()"
                />
            </validation-field>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12">
            <label>{{$t('message.score')}}</label><br/>
            <star-rating v-model="form.Score"></star-rating>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col md="4" sm="12">
            <b-input-group :class="{ 'error': $v.form.recaptchaVerified.$error }" class="mb-3">
              <vue-recaptcha
                v-if="sitekey"
                :loadRecaptchaScript="true"
                :sitekey="sitekey"
                :language="this.$route.params.lang"
                @expired="onExpired"
                @verify="onVerify"
                ref="recaptcha"
              >
              </vue-recaptcha>

              <validation-field
                v-else
                :label="$t('message.recapthca_in_dev')"
                :v="$v.form.recaptchaVerified">
                <input class="form-control" type="checkbox" v-model="form.recaptchaVerified">
              </validation-field>

              <span class="error" v-if="$v.form.recaptchaVerified.$error">Obbligatorio</span>
            </b-input-group>
          </b-col>
        </b-row>
        <hr class="mb-4">
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              :class="{invalid:$v.form.$invalid}"
              block
              style="margin-bottom: 2px !important;"
              class="bg-theme-secondary"
              type="submit">
              {{$t('message.button_send_text')}}
            </b-button>
          </b-col>
          <b-col sm="12" md="6">
            <b-button
              block type="button"
              v-on:click="reset()"
              variant="secondary">
              {{$t('message.button_reset_text')}}
            </b-button>
          </b-col>
        </b-row>
      </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidationField from "../../components/ui/ValidationField";
  import ApiResolver from '../../components/common/ApiResolver';
  import Api from '../../components/common/Api';
  import {required,email,sameAs} from 'vuelidate/lib/validators';
  import StarRating from 'vue-star-rating';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import VueRecaptcha from 'vue-recaptcha';

  export default {
    name: "Feedback",
    components: {
      ValidationField,
      StarRating,
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        isLoading: false,
        sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
        form: {
          Feedback: null,
          Score: 0,
          Referral: 'admin',
          Referral_Url: '/anonymous_feedback',
          Name: null,
          Email: null,
          recaptchaVerified: false
        },
        error_message: null
      }
    },
    computed: {},
    methods: {
      onVerify: function (response) {
        this.form.recaptchaVerified = true
      },
      onExpired: function () {
        //console.log('Expired')
      },
      resetRecaptcha() {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
      submit() {
        this.form.Score = this.form.Score.toString();
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        this.isLoading = true;
        Api
          .put(ApiResolver.get('PUBLIC_FEEDBACK'), JSON.stringify(this.form))
          .then(response => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.feedback_sent')
            });
            this.$router.push({name: 'categories'});
          })
          .catch(reason => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: reason.title + ' : ' + reason.detail
            });
          });
      },
      reset() {
        this.form = {
          Feedback: null,
          Score: 0,
          Name: null,
          Email: null
        };
        this.$v.form.reset();
      }
    },
    mounted() {
    },
    validations: {
      form: {
        Name: {
          required: required
        },
        Email: {
          required: required,
          email
        },
        Feedback: {
          required: required,
        },
        recaptchaVerified: {
          sameAs: sameAs(() => true)
        }
      }
    },
  }
</script>

<style type="text/css">
  .vue-star-rating-rating-text {
    display: none
  }

</style>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
